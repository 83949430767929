<template>
  <div class="internal-product">
    <PageLoader />
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area mb-30">
      <div class="">
        <div class="row sim_only ">
            <div class="img1">
                <div class="img1all col-12">
                  <div class="row justify-content-around">
                      <div class="col-md-6 text-left">
                        <div class="breadcrumb-wrap">
                          <nav aria-label="breadcrumb">
                            <ul class="breadcrumb">
                              <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                              </li>
                              <li class="breadcrumb-item">
                                <router-link
                                  :to="'/categories/' + this.$route.params.id"
                                  tag="a"
                                  >{{ this.$route.params.id }}</router-link>
                              </li>
                              <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                  :to="
                                    '/categories/' +
                                      this.$route.params.id +
                                      '/' +
                                      this.$route.params.brand
                                  "
                                  tag="a"
                                  >{{ this.$route.params.brand }}</router-link
                                >
                              </li>
                              <li class="breadcrumb-item active" aria-current="page">
                                {{ singleOffer.title }}
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <h1 class="pb-0">{{ singleOffer.title }}</h1>
                        <div class="product_desc" v-html="singleOffer.desc"></div>
                        <a href="#contents" class="btn btn-outline-light btn-lg mt-3">All Details</a>
                        
                      </div>
                      <div class="col-md-3">
                        <div class="bg_white_radious d-flex align-items-center">
                          <img :src="publicPath + singleOffer.img" :alt="singleOffer.title" style="width: 40%;" />
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="blogs-page pt-5 mt-5">
      <div class="blog-area-wrapper pt-40 pb-70 mb-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 order-first">
              <div class="row  m-0"
                v-show="
                  (this.$route.query.reseller != null) |
                    (this.$route.query.data != null) |
                    (this.$route.query.text != null) |
                    (this.$route.query.minutes != null) |
                    (this.$route.query.simNetwork != null) |
                    (this.$route.query.network != null) |
                    (this.$route.query.product != null)
                "
              >
                <template v-for="(filter, i) in mohand.data">
                  <div :key="filter.title + i" class="card mobile_list border border-secondary mb-3 col-lg-12 col-md-5 col-xs-12 col-sm-12">
                      <div class="row  d-flex align-items-center">
                        <div class="col-">
                          <div class="row">
                            <div class="img_free col-lg-2 text-center m-auto">
                              <img style="width: 4rem; display: block;margin: auto;margin-top: .33rem;margin-bottom: .33rem;" :src="publicPath + filter.img" alt="">
                              <img style="width: 4rem; display: block;margin: auto;" 
                              v-if="filter.network.img && filter.network.img != undefined" :src="publicPath + filter.network.img" alt="">
                              
                            </div>
                            <div class="col-md-8 card-body ">
                              <div class="row">
                                <div class="col-md-12">
                                    <h5 class="card-title">{{ filter.title }}</h5>
                                    <p>{{filter.contract}} month contract</p>
                                </div>
                                <div class="col-lg-12 pb-0 pl-0">
                                    <ul class="card_info row d-flex align-items-center justify-content-center">
                                      <li v-if="filter.data" class="col-2 border-right">
                                            <p v-if="filter.data === '123456789'"><strong style="font-size: 20px;">Unltd</strong><br> Data</p>
                                            <p  v-else-if="filter.data.length >= 4 && filter.data >= 1000">
                                              <strong style="font-size: 20px;">{{ filter.data / 1000 }}GB</strong><br> Data
                                            </p>
                                            <p v-else><strong style="font-size: 20px;">{{ filter.data }}MB</strong><br> Data</p>
                                        </li>
                                        <li v-if="filter.minutes" class="col-2 border-right">
                                            <p v-if="filter.minutes === '987654321' || filter.minutes === '123456789'">
                                              <strong style="font-size: 20px;">Unltd</strong><br>minutes
                                            </p>
                                            <p v-else>
                                              <strong style="font-size: 20px;">{{filter.minutes}}</strong><br>minutes
                                            </p>
                                        </li>
                                        <li v-if="filter.text" class="col-2 border-right">
                                            <p v-if="filter.text === '987654321' || filter.text === '123456789'">
                                              <strong style="font-size: 20px;">Unltd</strong><br>Texts
                                            </p>
                                            <p v-else>
                                              <strong style="font-size: 20px;">{{filter.text}}</strong><br>Texts
                                            </p>
                                        </li>
                                        
                                        <li class="col-2 border-right"><p><strong style="font-size: 20px;">{{filter.contract}}</strong> month contract</p></li>
                                        <li class="col-2">
                                            <p v-if="filter.upfront_cost === '1'"><strong style="font-size: 20px;">£0</strong><br> upfront</p>
                                            <p v-else><strong style="font-size: 20px;">£{{ filter.upfront_cost }}</strong><br> upfront</p>
                                        </li>
                                    </ul>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 mt-2 text-center">
                              <div class="box-label cust_label">
                                <div class="label-product label_new" v-if="filter.pormoted == 1" >
                                  <span>Pormoted</span>
                                </div>
                              </div>
                                
                                <p><strong style="font-size: 20px;">£{{ filter.monthly_cost }}</strong><br>Per Month</p>
                                <a :href="filter.link" class="btn btn-info  see_deal">Buy Now</a>
                                <img style="width: 4.5rem;margin-bottom: 0.5rem;" v-if="filter.reseller.img" :src="publicPath + filter.reseller.img">
                                <img style="width: 4.5rem;margin-bottom: 0.5rem;" v-else :src="publicPath + filter.network.img">
                                <!-- <a @click=" compareF(filter.slug), (isDisable = filter.id) "
                                  class="compare btn btn-outline-info mt-2"
                                  :class="{ disable: isDisable === filter.id } " title="Compare" >
                                  <i class="lnr lnr-sync"></i>
                                  Compare
                                </a> -->
                            </div>
                          </div>
                        </div>
                          

                          
                      </div>
                  </div>
                </template>
                <template class="col-12" v-if="mohand.data < 1">
                  <div class="grid-no-results">
                    <p class="grid-no-results__message">
                      no deals found in your depend on your filtration
                    </p>
                  </div>
                </template>
                <div class="col-12 mt-5">
                  <nav aria-label="Page navigation example" class="row justify-content-center">
                    <pagination :limit="2" :align="'center'" :data="mohand" @pagination-change-page="getFilter" >
                      <span slot="prev-nav">&lt; Previous</span>
                      <span slot="next-nav">Next &gt;</span>
                    </pagination>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="row m-0 mb-1">
                <div class="color_storage_sec mt-15 mb-15 col-12">
                  <div class="pro-social-sharing">
                    <social-sharing :url="'http://comparemobiledeals.co.uk/categories/' +  this.$route.params.id + '/' + this.$route.params.brand + '/' + singleOffer.slug + '?product=' + singleOffer.id " tag="a"
                      :title="singleOffer.title"  description quote hashtags twitter-user inline-template
                    >
                      <div class="social_icons">
                        <span>share :</span>
                        <network network="facebook">
                          <li class="list-inline-item">
                            <a class="bg-facebook" title="Facebook">
                              <i class="fab fa-facebook-square"></i>
                            </a>
                          </li>
                        </network>
                        <network network="googleplus">
                          <li class="list-inline-item">
                            <a class="bg-google" title="Google Plus">
                              <i class="fab fa-google-play"></i>
                            </a>
                          </li>
                        </network>
                        <network network="linkedin">
                          <li class="list-inline-item">
                            <a class="bg-twitter" title="Twitter">
                              <i class="fab fa-linkedin"></i>
                            </a>
                          </li>
                        </network>
                        <network network="twitter">
                          <li class="list-inline-item">
                            <a class="bg-twitter" title="Twitter">
                              <i class="fab fa-twitter-square"></i>
                            </a>
                          </li>
                        </network>
                      </div>
                    </social-sharing>
                    <ul></ul>
                  </div>
                </div>
              </div>
              <div class="row bg_sky m-auto pb-5 ">
                <div class="network col-lg-12 box_shadow pt-4">
                  <h2 class="pb-1 text-center">Filter</h2>
                  <h4 class="pb-1 pt-3 pb-2">Networks</h4>
                  <div class="row justify-content-center network">
                    <div class="network-sub-product" :style="{'border' : $route.query.network == '' ? '2px solid transparent' : '1px solid black'}">
                      <router-link @click="filnetworkAdd('')"
                        :to="
                          '?reseller=' +
                            filresellerC +
                            '&data=' +
                            fildataC +
                            '&text=' +
                            filtextC +
                            '&minutes=' +
                            filminutesC +
                            '&simNetwork=' +
                            filsimNetworkC +
                            '&network=' +
                            '&product=' +
                            returnProduct +
                            '&color=' +
                            filcolorC +
                            '&upfront_cost=' +
                            filufrontC +
                            '&monthly_cost=' +
                            filmonthlyC +
                            '&storage=' +
                            filstorageC +
                            '&contract=' +
                            contractC
                        "
                        tag="a"
                      >
                        <img style="width: 100%;" src="./../../../../../public/assets/img/filter_any.jpg" alt="any network">
                      </router-link>
                    </div>
                    <div class="network-sub-product" v-for="(network, i) in networks" :key="network.title + i"
                      :style="{'border' : $route.query.network == network.id ? '2px solid transparent' : '1px solid black'}">
                      <router-link @click="filnetworkAdd(network.id)"
                        :to="
                          '?reseller=' +
                            filresellerC +
                            '&data=' +
                            fildataC +
                            '&text=' +
                            filtextC +
                            '&minutes=' +
                            filminutesC +
                            '&simNetwork=' +
                            filsimNetworkC +
                            '&network=' +
                            network.id +
                            '&product=' +
                            returnProduct +
                            '&color=' +
                            filcolorC +
                            '&upfront_cost=' +
                            filufrontC +
                            '&monthly_cost=' +
                            filmonthlyC +
                            '&storage=' +
                            filstorageC +
                            '&contract=' +
                            contractC
                        "
                        tag="a"
                      >   
                        <img :src="publicPath + network.img" :alt="network.title">
                      </router-link>
                    </div>
                  </div>

                </div>
                <div class="network reseller col-lg-12 box_shadow pb-4 pt-3">
                  <h4 class="pb-1">Resellers:</h4>
                  <div class="row justify-content-center network">
                    <div class="network-sub-product" :style="{'border' : $route.query.reseller == '' ? '2px solid transparent' : '1px solid black '}">
                      <router-link @click="filresellerAdd('')"
                            :to="
                              '?reseller=' +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                filcolorC +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract=' +
                                contractC +
                                '&speed=' +
                                filSpeedC
                            "
                            tag="a"
                          >
                        <img style="width: 100%;" src="./../../../../../public/assets/img/any_reseller.jpg" alt="any network">
                      </router-link>
                    </div>
                    <div class="network-sub-product" v-for="(reseller, i) in reseller" :key="reseller.title + 'po' + i"
                      :style="{'border' : $route.query.reseller == reseller.id ? '2px solid transparent' : '1px solid black '}"
                      >
                      <router-link @click="filresellerAdd(reseller.id)"
                        :class="{ active: active_el == reseller.id + 50 }"
                        :to="
                        '?reseller=' +
                          reseller.id +
                          '&data=' +
                          fildataC +
                          '&text=' +
                          filtextC +
                          '&minutes=' +
                          filminutesC +
                          '&simNetwork=' +
                          filsimNetworkC +
                          '&network=' +
                          filnetworkC +
                          '&product=' +
                          returnProduct +
                          '&color=' +
                          filcolorC +
                          '&upfront_cost=' +
                          filufrontC +
                          '&monthly_cost=' +
                          filmonthlyC +
                          '&storage=' +
                          filstorageC +
                          '&contract=' +
                          contractC +
                          '&speed=' +
                          filSpeedC
                      "
                      tag="a"
                      >
                        <img :src="publicPath + reseller.img" :alt="reseller.title">
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="this.$route.params.id == 'Mobile_Phones' || this.$route.params.id == 'Tablets' ">
                  <h4 class="pb-1 pt-3 pb-2">Colours</h4>
                  <div class="color-option">
                      <ul>
                        <li
                          v-for="(color, i) in singColor"
                          :key="color.code + i"
                          :class="{ active: colorQ == color.id }"
                          :style="{'background': color.code, 'border' : $route.query.color == color.id ? '2px solid transparent' : '1px solid black'}"
                        >
                          <router-link
                            :title="singColor.title"
                            @click.native="filcolorAdd(color.id)"
                            :style="'background:' + color.code"
                            :to="
                              '?reseller=' +
                                filresellerC +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                color.id +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract=' +
                                contractC
                            "
                            tag="a"
                          ></router-link>
                        </li>
                      </ul> 
                  </div>
                  <h4 class="pb-1 pt-3 pb-2">Storage</h4>
                  <div class="storage-option">
                    <ul>
                      <li
                        class="storge"
                        v-for="(storage, i) in singleStorage"
                        :key="storage.title + i"
                        :class="{ active: storageQ == storage.id }"
                        :style="{'border' : $route.query.storage == storage.id ? '2px solid transparent' : '1px solid black'}"
                      >
                        <router-link
                          :title="storage.title"
                          @click.native="filstorageAdd(storage.id)"
                          :to="
                            '?reseller=' +
                              filresellerC +
                              '&data=' +
                              fildataC +
                              '&text=' +
                              filtextC +
                              '&minutes=' +
                              filminutesC +
                              '&simNetwork=' +
                              filsimNetworkC +
                              '&network=' +
                              filnetworkC +
                              '&product=' +
                              returnProduct +
                              '&color=' +
                              filcolorC +
                              '&upfront_cost=' +
                              filufrontC +
                              '&monthly_cost=' +
                              filmonthlyC +
                              '&storage=' +
                              storage.id +
                              '&contract=' +
                              contractC
                          "
                          tag="a"
                          >{{ storage.title }}</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-12 filter_sec box_shadow pt-4">
                    <div class="row">
                      <div class="filter col-sm-12 col-lg-12 col-md-12">
                        <label>monthly cost:</label>
                        <ul class="list row m-0">
                          <li class="option" :style="{'border' : $route.query.monthly_cost == '' ? '2px solid transparent' : '1px solid black'}">
                            <router-link
                              @click.native="filmonthlyCAdd('')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >
                                Any
                              </router-link>
                          </li>
                          <li v-for="(offer, i) in monthlyCostArray" :key="offer + i * 2" class="option"
                            :style="{'border' : $route.query.monthly_cost == offer ? '2px solid transparent' : '1px solid black'}"
                            >
                            <router-link
                              @click.native="filufrontAdd(offer)"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  offer +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >Up to £{{ offer }}</router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="filter col-sm-12 col-lg-12 col-md-12">
                        <label>Upfront cost:</label>
                        <ul class="list row m-0">
                          <li class="option" :style="{'border' : $route.query.upfront_cost == '' ? '2px solid transparent' : '1px solid black'}">
                            <router-link @click.native="filufrontAdd('')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                            >
                                Any
                            </router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.upfront_cost == '1' ? '2px solid transparent' : '1px solid black'}">
                            <router-link @click.native="filufrontAdd('1')"
                                :to="
                                  '?reseller=' +
                                    filresellerC +
                                    '&data=' +
                                    fildataC +
                                    '&text=' +
                                    filtextC +
                                    '&minutes=' +
                                    filminutesC +
                                    '&simNetwork=' +
                                    filsimNetworkC +
                                    '&network=' +
                                    filnetworkC +
                                    '&product=' +
                                    returnProduct +
                                    '&color=' +
                                    filcolorC +
                                    '&upfront_cost=1' +
                                    '&monthly_cost=' +
                                    filmonthlyC +
                                    '&storage=' +
                                    filstorageC +
                                    '&contract=' +
                                    contractC
                                "
                            >
                                £0 Upfront
                            </router-link>
                          </li>
                          <li v-for="(offer, i) in upfrontCostArray" :key="offer + i"
                            :style="{'border' : $route.query.upfront_cost == offer ? '2px solid transparent' : '1px solid black'}"
                            >
                            <router-link
                              class="option"
                              @click.native="filufrontAdd(offer)"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  offer +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                            >Up to £{{ offer }}</router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="filter col-sm-12 col-lg-12 col-md-12">
                        <label>Data:</label>
                        <ul class="list row m-0">
                          <li class="option" :style="{'border' : $route.query.data == '' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="fildataAdd('')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >Any</router-link>
                          </li>
                          <li v-for="(offer, i) in dataMBArray" :key="offer * 8 + i" class="option" 
                              :style="{'border' : $route.query.data == offer ? '2px solid transparent' : '1px solid black'}"
                            >
                            <router-link
                              @click.native="fildataAdd(offer)"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  offer +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >{{ offer }}MB+ data</router-link>
                          </li>
                          <li v-for="(offer, i) in dataGBArray" :key="offer * 882 + i" class="option"
                            :style="{'border' : $route.query.data == offer + '000' ? '2px solid transparent' : '1px solid black'}"
                            >
                            <router-link
                              @click.native="fildataAdd(offer + '000')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  offer +
                                  '000' +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >{{ offer }}GB+ data</router-link>
                          </li>
                          <li :style="{'border' : $route.query.data == '123456789' ? '2px solid transparent' : '1px solid black'}">
                            <router-link
                              @click.native="fildataAdd('123456789')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=123456789' +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >Unltd data</router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="filter col-sm-12 col-lg-12 col-md-12">
                        <label>minutes:</label>
                        <ul class="list row m-0">
                          <li class="option" :style="{'border' : $route.query.minutes == '' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="filminutesAdd('')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >Any </router-link>
                          </li>
                          <li v-for="(offer, i) in minutesArray" :key="offer * 2.4 + i" class="option"
                            :style="{'border' : $route.query.minutes == offer ? '2px solid transparent' : '1px solid black'}"
                            >
                            <router-link
                              @click.native="filminutesAdd(offer)"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=' +
                                  offer +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >{{ offer }}+ minutes</router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.minutes == '123456789' ? '2px solid transparent' : '1px solid black'}">
                            <router-link
                              @click.native="filminutesAdd('123456789')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  filtextC +
                                  '&minutes=123456789' +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >Unltd minutes</router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="filter col-sm-12 col-lg-12 col-md-12">
                        <label>texts:</label>
                        <ul class="list row m-0">
                          <li class="option" :style="{'border' : $route.query.text == '' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="filtextAdd('')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >Any</router-link>
                          </li>
                          <li v-for="(offer, i) in textArray" :key="offer * 6.02 + i" class="option"
                            :style="{'border' : $route.query.text == offer ? '2px solid transparent' : '1px solid black'}"
                            >
                            <router-link
                              @click.native="filtextAdd(offer)"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=' +
                                  offer +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >{{ offer }}+ texts</router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.text == '123456789' ? '2px solid transparent' : '1px solid black'}">
                            <router-link
                              @click.native="filtextAdd('')"
                              :to="
                                '?reseller=' +
                                  filresellerC +
                                  '&data=' +
                                  fildataC +
                                  '&text=123456789' +
                                  '&minutes=' +
                                  filminutesC +
                                  '&simNetwork=' +
                                  filsimNetworkC +
                                  '&network=' +
                                  filnetworkC +
                                  '&product=' +
                                  returnProduct +
                                  '&color=' +
                                  filcolorC +
                                  '&upfront_cost=' +
                                  filufrontC +
                                  '&monthly_cost=' +
                                  filmonthlyC +
                                  '&storage=' +
                                  filstorageC +
                                  '&contract=' +
                                  contractC
                              "
                              >Unltd texts</router-link>
                          </li>
                        </ul>
                      </div>
                      <div class="filter col-sm-12 col-lg-12 col-md-12">
                        <label>Contract length:</label>
                        <ul class="list row m-0">
                          <li class="option" :style="{'border' : $route.query.contract == '' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="contractAdd('')"
                            :to="
                              '?reseller=' +
                                filresellerC +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                filcolorC +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract='
                            "
                            >Any</router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.contract == '1' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="contractAdd('1')"
                            :to="
                              '?reseller=' +
                                filresellerC +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                filcolorC +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract=1'
                            "
                          >
                            1 Months</router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.contract == '12' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="contractAdd('12')"
                            :to="
                              '?reseller=' +
                                filresellerC +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                filcolorC +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract=12'
                            "
                          >
                            12 Months</router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.contract == '24' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="contractAdd('24')"
                            :to="
                              '?reseller=' +
                                filresellerC +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                filcolorC +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract=24'
                            "
                          >
                            24 Months</router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.contract == '30' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="contractAdd('30')"
                            :to="
                              '?reseller=' +
                                filresellerC +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                filcolorC +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract=30'
                            "
                          >
                            30 Months</router-link>
                          </li>
                          <li class="option" :style="{'border' : $route.query.contract == '36' ? '2px solid transparent' : '1px solid black'}">
                             <router-link
                              @click.native="contractAdd('36')"
                            :to="
                              '?reseller=' +
                                filresellerC +
                                '&data=' +
                                fildataC +
                                '&text=' +
                                filtextC +
                                '&minutes=' +
                                filminutesC +
                                '&simNetwork=' +
                                filsimNetworkC +
                                '&network=' +
                                filnetworkC +
                                '&product=' +
                                returnProduct +
                                '&color=' +
                                filcolorC +
                                '&upfront_cost=' +
                                filufrontC +
                                '&monthly_cost=' +
                                filmonthlyC +
                                '&storage=' +
                                filstorageC +
                                '&contract=36'
                            "
                          >
                            36 Months</router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <section id="related_products" v-if="this.$route.params.id !== 'Sim_Only'">
      <div class="brand-area pb-20 product_offer_sec">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="section-title mt-0 pb-4">
                <h3><span>Related Products</span>…</h3>
              </div>
            </div>
            <div class="col-12">
              <swiper
                class="swiper d-flex align-items-center"
                :options="swiperOption"
              >
                <swiper-slide
                  v-for="(products, i) in products"
                  :key="products.title + i"
                >
                  <div
                    class="product-module-four-item mb-30"
                    v-if="products.offers"
                  >
                    <div class="product-module-caption">
                      <div class="manufacture-com">
                        <p>
                          <a>{{ products.title }}</a>
                        </p>
                      </div>
                      <div class="product-module-name">
                        <h4>
                          <a>Offers {{ products.offers }}</a>
                        </h4>
                        <router-link
                          :to="
                            '/categories/' +
                              products.category.slug +
                              '/' +
                              products.brand.slug +
                              '/' +
                              products.slug +
                              '?product=' +
                              products.id
                          "
                          tag="a"
                        >
                          + Compare now
                        </router-link>
                      </div>
                    </div>
                    <div
                      class="product-module-thumb thumb4"
                      style="width: 3rem;"
                    >
                      <router-link
                        :to="
                          '/categories/' +
                            products.category.slug +
                            '/' +
                            products.brand.slug +
                            '/' +
                            products.slug +
                            '?product=' +
                            products.id
                        "
                        tag="a"
                      >
                        <img
                          v-if="products.img"
                          class="d-block m-auto"
                          :src="publicPath + products.img"
                          alt
                        />
                      </router-link>
                    </div>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    
    <section id="contents" v-if="singleOffer.content != 0">
      <div class="description description_sec pt-90 mt-5 pt-5 description_section">
        <div class="container">
          <div class="row m-0">
            <div class="col-lg-8 desc_body">
              <div
                v-for="(content, i) in this.singleOffer.contents"
                :key="i"
                :id="'tab-' + content.title"
                class="mt-20 pb-20"
              >
                <h3 class="text-left mb-40 pt-4">{{ content.title }}</h3>
                <div v-html="content.desc"></div>
                <div class="mt-20" style="border:solid 2px gray"></div>
              </div>
            </div>
            <div class="col-lg-4 Networks_Brands_img">
              <div class="row bg_sky m-auto pb-5">
                <div class="network col-lg-12 box_shadow pt-4">
                    <h4 class="pb-1 pt-3 pb-2">Our Top Providers</h4>
                  <div class="row justify-content-center">
                    <template v-for="(network, i) in networks" >
                      <router-link :key="network.title + i" :to=" '/networks/' + network.slug + '?network=' + network.id " tag="a" 
                        class="network-sub-product brand_phone brand_product">
                        <img @click="filnetworkAdd(network.id)" :src="publicPath + network.img " alt>
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/units/PageLoader.vue";
import $ from "jquery";
export default {
  name: "singleProduct",
  components: { PageLoader },
  data() {
    return {
      singleOffer: [],
      singColor: [],
      singleStorage: [],
      mohand: {},
      active_el: 0,
      reseller: [],
      networks: [],
      monthlyCost: [],
      frontCost: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getSingleOffer();
    this.getColor();
    this.getStorage();
    this.getFilter();
    this.getreseller();
    this.getNetwork();
    this.getMonthlyCost();
    this.getFrontCost();
    // product view mode change js
    $(".product-view-mode a").on("click", function(e) {
      e.preventDefault();

      var shopProductWrap = $(".shop-product-wrap");
      var viewMode = $(this).data("target");

      $(".product-view-mode a").removeClass("active");
      $(this).addClass("active");
      shopProductWrap.removeClass("grid list column_3").addClass(viewMode);
    });
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    },
    getquery() {
      return this.$route.query;
    },
    returnProduct() {
      return this.singleOffer.id;
    },
    returnProductSlug() {
      return this.$route.params.product;
    },
    networkPramter() {
      return this.$route.params.id;
    },
    catPramter() {
      return this.$route.query.cat_slug;
    },
    brandPramter() {
      return this.$route.query.brand_slug;
    },
    filresellerC() {
      // return [
      //   this.$route.query.filreseller == null
      //     ? ""
      //     : this.$route.query.filreseller
      // ]
      return this.filreseller;
    },
    fildataC() {
      return this.fildata;
    },
    filtextC() {
      return this.filtext;
    },
    filminutesC() {
      return this.filminutes;
    },
    filSpeedC() {
      return [
        this.$route.query.speed == "undefined" ? " " : this.$route.query.speed
      ];
    },
    filsimNetworkC() {
      return this.filsimNetwork;
    },
    filnetworkC() {
      return this.filnetwork;
    },
    contractC() {
      // return this.filcontract;
      return [
        this.$route.query.contract == "undefined"
          ? " "
          : this.$route.query.contract
      ];
    },
    filmonthlyC() {
      return [
        this.$route.query.monthly_cost == "undefined"
          ? " "
          : this.$route.query.monthly_cost
      ];
      // return this.$route.query.monthly_cost;
    },
    filufrontC() {
      return [
        this.$route.query.upfront_cost == "undefined"
          ? ""
          : this.$route.query.upfront_cost
      ];
      // return this.$route.query.upfront_cost;
    },
    filcolorC() {
      return this.filcolor;
    },
    filstorageC() {
      return this.filstorage;
    },
    colorQ() {
      return this.$route.query.color;
    },
    storageQ() {
      return this.$route.query.storage;
    }
    // getQuery() {
    //   return this.$route.query;
    // }
  },
  watch: {
    getFullPath() {
      this.getSingleOffer();
      this.getColor();
      this.getStorage();
      this.getFilter();
    },
    getquery() {
      this.getFilter();
      this.getreseller();
    }
  },
  methods: {
    fildataAdd(id) {
      return (this.fildata = id);
    },
    filtextAdd(id) {
      return (this.filtext = id);
    },
    filminutesAdd(id) {
      return (this.filminutes = id);
    },
    filSpeedAdd(id) {
      return (this.filSpeed = id);
    },
    filsimNetworkAdd(id) {
      return (this.filsimNetwork = id);
    },
    filnetworkAdd(id) {
      return (this.filnetwork = id);
    },
    filresellerAdd(id) {
      return (this.filreseller = id);
    },
    filmonthlyAdd(id) {
      return (this.filmonthly = id);
    },
    filufrontAdd(id) {
      return (this.filufront = id);
    },
    filcolorAdd(id) {
      return (this.filcolor = id);
    },
    filstorageAdd(id) {
      return (this.filstorage = id);
    },
    contractAdd(id) {
      return (this.filcontract = id);
    },
    activate: function(el) {
      this.active_el = el;
    },
    getSingleOffer() {
      this.isLoading = true;
      this.$http
        .get("product/" + this.$route.params.product)
        .then(response => {
          this.singleOffer = response.data.data;
          response => (this.singleOffer = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getColor() {
      this.isLoading = true;
      this.$http
        .get("productColor?product=" + this.$route.params.product)
        .then(response => {
          this.singColor = response.data.data;
          response => (this.singColor = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getStorage() {
      this.isLoading = true;
      this.$http
        .get("productStorage?product=" + this.$route.params.product)
        .then(response => {
          this.singleStorage = response.data.data;
          response => (this.singleStorage = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    getFilter(page = 1) {
      this.loading = true;
      this.$http
        .get(
          "filter?count=9&reseller=" +
            [
              this.$route.query.reseller == "undefined"
                ? " "
                : this.$route.query.reseller
            ] +
            "&data=" +
            [
              this.$route.query.data == "undefined"
                ? " "
                : this.$route.query.data
            ] +
            "&product=" +
            [
              this.$route.query.product == "undefined"
                ? " "
                : this.$route.query.product
            ] +
            "&text=" +
            [
              this.$route.query.text == "undefined"
                ? " "
                : this.$route.query.text
            ] +
            "&minutes=" +
            [
              this.$route.query.minutes == "undefined"
                ? " "
                : this.$route.query.minutes
            ] +
            "&simNetwork=" +
            [
              this.$route.query.simNetwork == "undefined"
                ? " "
                : this.$route.query.simNetwork
            ] +
            "&network=" +
            [
              this.$route.query.network == "undefined"
                ? " "
                : this.$route.query.network
            ] +
            "&color=" +
            [
              this.$route.query.color == "undefined"
                ? " "
                : this.$route.query.color
            ] +
            "&storage=" +
            [
              this.$route.query.storage == "undefined"
                ? " "
                : this.$route.query.storage
            ] +
            "&monthly_cost=" +
            [
              this.$route.query.monthly_cost == "undefined"
                ? " "
                : this.$route.query.monthly_cost
            ] +
            "&upfront_cost=" +
            [
              this.$route.query.upfront_cost == "undefined"
                ? " "
                : this.$route.query.upfront_cost
            ] +
            "&contract=" +
            [
              this.$route.query.contract == "undefined"
                ? " "
                : this.$route.query.contract
            ] +
            "&page=" +
            page
        )
        .then(response => {
          this.mohand = response.data;
          response => (this.mohand = response.data);
          this.getProduct();
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getNetwork() {
      this.loading = true;
      this.$urlmain
        .get("network-popular.json")
        .then(response => {
          this.networks = response.data.data;
          response => (this.networks = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getFrontCost() {
      this.loading = true;
      this.$urlmain
        .get("offers-upfront.json")
        .then(response => {
          this.frontCost = response.data;
          response => (this.frontCost = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getMonthlyCost() {
      this.loading = true;
      this.$urlmain
        .get("offers-monthly.json")
        .then(response => {
          this.monthlyCost = response.data;
          response => (this.monthlyCost = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getreseller() {
      this.loading = true;
      this.$urlmain
        .get("reseller-popular.json")
        .then(response => {
          this.reseller = response.data.data;
          response => (this.reseller = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="scss" scoped>
.thumb-example {
  height: auto;
  background-color: white;
}

.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  &.gallery-top {
    height: 100%;
    width: 100%;
  }
  &.gallery-thumbs {
    height: 33%;
    box-sizing: border-box;
    padding: 0;
  }
  &.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide {
    margin-top: 0.5em;
  }
}
</style>
